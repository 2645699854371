import { decode } from '../utils/jwt';
// eslint-disable-next-line import/named
import { resellers, reseller } from './mock/resellers';
import { API_URL } from './config';

const options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
class ResellerApi {
  getResellers() {
    try {
      const rqOptions = { ...options, method: 'GET' };

      return fetch(`${API_URL}resellers`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(resellers);
    }
  }

  setupNewReseller(data) {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      const { token } = decode(accessToken);

      const rqOptions = {
        ...options,
        method: 'POST',
        headers: { ...options.headers, Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}resellers`, rqOptions)
        .then((r) => r.json())
        .then(() => {});
    } catch (e) {
      console.log({ e });
      return Promise.resolve(resellers);
    }
  }

  getDetailedResellers(accessToken) {
    try {
      const { token } = decode(accessToken);

      const rqOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      return fetch(`${API_URL}users`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(resellers);
    }
  }

  getReseller(id) {
    try {
      const rqOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      return fetch(`${API_URL}reseller/${id}`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(reseller);
    }
  }

  getResellerByDomain(domain) {
    try {
      const rqOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      return fetch(`${API_URL}reseller/domain/${domain}`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => ({}));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(reseller);
    }
  }

  updateReseller(data) {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const { token } = decode(accessToken);
      const rqOptions = {
        ...options,
        headers: { ...options.headers, Authorization: `Bearer ${token}` },
        method: 'PUT',
        body: JSON.stringify(data),
      };

      return fetch(`${API_URL}reseller/${data.id}`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(reseller);
    }
  }

  updateResellerTheme(id, data) {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const { token } = decode(accessToken);
      const rqOptions = {
        ...options,
        headers: {
          // ...options.headers,
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: data,
      };

      return fetch(`${API_URL}reseller/${id}/theme`, rqOptions)
        .then((r) => r.json())
        .then((d) => {
          if (d.error) {
            return Promise.reject(new Error(d.messages[0]));
          }
          return d.data;
        })
        .catch(() => Promise.reject(new Error('Internal server error')));
    } catch (e) {
      console.log({ e });
      return Promise.resolve(reseller);
    }
  }
}

export const resellerApi = new ResellerApi();
