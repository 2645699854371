/* eslint-disable no-unreachable */
/* eslint-disable operator-linebreak */
import { createContext, useReducer, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import perTicketPrice from '../utils/pricing';
// import numeral from 'numeral';

const sumItems = (items) => {
  // Storage(items);

  const itemCount = items.reduce((total, product) => total + product.qty, 0);

  const cartTotal = items.reduce((total, product) => {
    const { price, qty } = product;

    const currentTotal = total + price * qty;

    return currentTotal;
  }, 0);

  return { itemCount, cartTotal };
};

const initialState = {
  cartItems: [],
  ...sumItems([]),
  checkout: false,
  user: {},
  coupon: {
    code: '',
  },
};

const toggleSetupFee = (items) =>
  items
    .filter((item) => item.name !== 'Setup Fee')
    .reduce((allItems, item) => {
      if (item.type !== 'stadium') {
        return allItems.concat([item]);
      }

      if (item.qty < 11) {
        const fee = {
          id: item.feeId,
          type: 'addon',
          name: 'Setup Fee',
          price: numeral(10).value(),
          qty: numeral(1).value(),
        };
        return allItems.concat([item, fee]);
      }

      return allItems.concat([item]);
    }, []);

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  ADD_PRODUCT: (state, { payload }) => {
    const items = toggleSetupFee([...state.cartItems, payload]);
    return {
      ...state,
      ...sumItems(items),
      cartItems: items,
    };
  },
  REMOVE_PRODUCT: (state, { payload }) => {
    const items = toggleSetupFee(
      state.cartItems.filter((item) => item.id !== payload.id)
    );
    return {
      ...state,
      ...sumItems(items),
      cartItems: items,
    };
  },
  INCREASE: (state, { payload }) => {
    state.cartItems[state.cartItems.findIndex((item) => item.id === payload.id)]
      .qty++;

    return {
      ...state,
      ...sumItems(state.cartItems),
      cartItems: [...state.cartItems],
    };
  },
  DECREASE: (state, { payload }) => {
    state.cartItems[state.cartItems.findIndex((item) => item.id === payload.id)]
      .qty--;

    return {
      ...state,
      ...sumItems(state.cartItems),
      cartItems: [...state.cartItems],
    };
  },
  SET_QTY: (state, { payload }) => {
    state.cartItems[
      state.cartItems.findIndex((item) => item.id === payload.id)
    ].qty = numeral(payload.qty).value();

    if (
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === payload.id)
      ].type !== 'addon'
    ) {
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === payload.id)
      ].price = perTicketPrice(numeral(payload.qty).value());
    }

    const items = toggleSetupFee(state.cartItems);

    const newState = {
      ...state,
      ...sumItems(items),
      cartItems: items,
    };

    return newState;
  },
  CHECKOUT: () => ({
    cartItems: [],
    checkout: true,
    ...sumItems([]),
  }),
  CLEAR: () => ({
    cartItems: [],
    ...sumItems([]),
  }),
};

// eslint-disable-next-line no-confusing-arrow
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const CartContext = createContext({
  ...initialState,
  platform: 'JWT',
  addItem: () => Promise.resolve(),
  removeItem: () => Promise.resolve(),
  increase: () => Promise.resolve(),
  decrease: () => Promise.resolve(),
  handleCheckout: () => Promise.resolve(),
  clearCart: () => Promise.resolve(),
});

export const CartProvider = (props) => {
  const { children } = props;

  const [cartItems, saveCartItems] = useLocalStorage('cartItems', []);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    cartItems: toggleSetupFee(cartItems),
    ...sumItems(toggleSetupFee(cartItems)),
  });

  const addProduct = (payload) => dispatch({ type: 'ADD_PRODUCT', payload });

  const removeProduct = (payload) =>
    dispatch({ type: 'REMOVE_PRODUCT', payload });

  useEffect(() => {
    saveCartItems(toggleSetupFee(state.cartItems));
  }, [state.cartItems]);

  const increase = (payload) => {
    console.log('increase', { payload });

    dispatch({ type: 'INCREASE', payload });
  };

  const decrease = (payload) => dispatch({ type: 'DECREASE', payload });

  const setQty = (payload) => {
    console.log('setQty', { payload });
    dispatch({ type: 'SET_QTY', payload });
  };

  const clearCart = (payload) => dispatch({ type: 'CLEAR', payload });

  const handleCheckout = (payload) => dispatch({ type: 'CHECKOUT', payload });

  return (
    <CartContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        setQty,
        removeProduct,
        addProduct,
        increase,
        decrease,
        clearCart,
        handleCheckout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartContext;
