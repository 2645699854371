import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Ga = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      window.gtag('event', 'page_view', { page_path: pathname });
    } catch (e) {
      console.log({ e });
    }
  }, [pathname]);

  return null;
};

export default Ga;
