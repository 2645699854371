import { Box, Grid, Container, Typography } from '@material-ui/core';

const HomeOverview = (props) => (
	<Box
		sx={{
			borderTop: '25px solid',
			borderColor: 'primary.main',
			pt: 4,
			pb: 6,
		}}
		{...props}
	>
		<Container maxWidth="lg">
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={5}
			>
				<Grid item xs={12} sm={12} md={5}>
					<img
						alt="Hero"
						src="/static/home/SET-Portrait.jpg"
						style={{ width: '100%' }}
					/>
				</Grid>

				<Grid item xs={12} sm={12} md={7}>
					<Typography
						sx={{
							color: 'primary.main',
							textAlign: {
								md: 'left',
								xs: 'center',
							},
						}}
						variant="h3"
					>
						ABOUT US
					</Typography>
					<Typography sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
						Special Event Ticketing was started in 1995 by Darren & Marcy
						Greenlee. Our goal is to provide the highest quality authentic
						personalized ticket invitations & accessories for your personal
						party or event. We launched the original version of
						SpecialEventTicketing.com in 2003, introducing our authentic ticket
						invitations to the world.
						<br />
						<br />
						In 2021 we rolled out our newly redesigned, mobile friendly site
						that makes it even easier to use our Virtual Ticket Design feature.
						The Virtual Ticket allows you to view and approve your personalized
						proof on-line therefore expediting the turnaround time of our
						products.
						<br />
						<br />
						We look forward to serving you for many years!
					</Typography>
				</Grid>
			</Grid>
		</Container>
	</Box>
);

export default HomeOverview;
