/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography, Link } from '@material-ui/core';
import Slider from 'react-slick';
// import encodeSVG from '../../lib/encodeSVG';
import './homeStyle.css';
import FeaturedTicketsContext from '../../contexts/FeaturedTicketsContext';
import { useContext } from 'react';
// import InspirationBg from './InspirationBg.svg';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const settings = {
// 	dots: true,
// 	infinite: true,
// 	speed: 500,
// 	slidesToShow: 1,
// 	slidesToScroll: 1,
// };
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
};

const waves2 = {
  backgroundImage: `url(
    "data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='80' height='80' patternTransform='scale(4) rotate(30)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(105, 0%, 100%, 1)'/><path d='M-20.133 4.568C-13.178 4.932-6.452 7.376 0 10c6.452 2.624 13.036 5.072 20 5 6.967-.072 13.56-2.341 20-5 6.44-2.659 13.033-4.928 20-5 6.964-.072 13.548 2.376 20 5s13.178 5.068 20.133 5.432'  stroke-width='0.5' stroke='hsla(355,77.6%,56.3%,1)' fill='none'/><path d='M-20.133 24.568C-13.178 24.932-6.452 27.376 0 30c6.452 2.624 13.036 5.072 20 5 6.967-.072 13.56-2.341 20-5 6.44-2.659 13.033-4.928 20-5 6.964-.072 13.548 2.376 20 5s13.178 5.068 20.133 5.432'  stroke-width='0.5' stroke='hsla(215,50%,22.7%,1)' fill='none'/><path d='M-20.133 44.568C-13.178 44.932-6.452 47.376 0 50c6.452 2.624 13.036 5.072 20 5 6.967-.072 13.56-2.341 20-5 6.44-2.659 13.033-4.928 20-5 6.964-.072 13.548 2.376 20 5s13.178 5.068 20.133 5.432'  stroke-width='0.5' stroke='hsla(182,42.6%,76.1%,1)' fill='none'/><path d='M-20.133 64.568C-13.178 64.932-6.452 67.376 0 70c6.452 2.624 13.036 5.072 20 5 6.967-.072 13.56-2.341 20-5 6.44-2.659 13.033-4.928 20-5 6.964-.072 13.548 2.376 20 5s13.178 5.068 20.133 5.432'  stroke-width='0.5' stroke='hsla(203,38.9%,44.3%,1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>"
  )`,
};

const getFilteredTickets = (tickets, ids) => {
  let filtered = tickets.filter((ticket) =>
    ids.includes(ticket.featureCategory)
  );

  while (filtered.length < 4) {
    filtered = [...filtered, ...filtered];
  }
  return filtered;
};

const PageInspiration = ({ applyFilter, ...props }) => {
  const { photoTickets, stadiumTickets, categoryId } = useContext(
    FeaturedTicketsContext
  );

  const categoryIds =
    categoryId && categoryId !== null && categoryId.constructor === Array
      ? categoryId
      : [categoryId];

  const filteredPhotoTickets =
    applyFilter && categoryIds && categoryId
      ? getFilteredTickets(photoTickets, categoryIds)
      : photoTickets;

  const filteredStadiumTickets =
    applyFilter && categoryIds && categoryId
      ? getFilteredTickets(stadiumTickets, categoryIds)
      : stadiumTickets;

  if (!photoTickets || !stadiumTickets) return null;

  const responsive = [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
      },
    },
  ];

  return (
    <Box
      // style={{  }}
      id="honeycomb"
      sx={{
        borderColor: 'primary.main',
        backgroundColor: 'white',
        ...waves2,
        pb: 4,
        pt: 6,
      }}
      {...props}
    >
      <Container
        // maxWidth="md"
        sx={{
          width: 'auto',
          maxWidth: '100% !important',
          mx: 5,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            backgroundColor: 'white',
            // textShadow: '1px 1px 2px rgba(0,0,0,1)',
            textAlign: {
              xs: 'center',
            },
          }}
          variant="h3"
        >
          NEED INSPIRATION? BROWSE OUR SAMPLE TICKETS.
        </Typography>
        {/* <img src="/static/home/0001.png" alt="" /> */}
        <Grid container sx={{ maxWidth: '100% !important' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ p: { xs: '40px 0px', sm: '40px 0px', md: '30px 64px' } }}
          >
            <Typography
              sx={{
                display: 'table',
                m: '0 auto 20px',
                color: 'textPrimary',
                backgroundColor: 'white',
                // textShadow: '0 0 2px rgba(0,0,0,.7)',
                textAlign: {
                  xs: 'center',
                },
              }}
              variant="h5"
            >
              STADIUM STYLE
            </Typography>
            <Box
              sx={{
                // display: 'flex',
                // alignItems: 'center',
                height: {
                  xs: '275px',
                  sm: '430px',
                  md: '275px',
                  lg: '390px',
                },
              }}
            >
              <Slider
                {...{
                  ...settings,
                  responsive,
                }}
                style={{ width: '100%' }}
              >
                {filteredStadiumTickets.map((el, i) => (
                  <div
                    className="stadiumSlider"
                    key={`${el.id}+${el.proofImage}`}
                  >
                    <Link
                      sx={{
                        position: 'relative',
                        display: 'block',
                        // height: '550px',
                        height: {
                          // xs: '360px',
                          xs: '245px',
                          sm: '400px',
                          md: '245px',
                          lg: '360px',
                        },
                      }}
                      component={RouterLink}
                      to={`/Build/Stadium/e/${el.id}`}
                      // onClick={() => {
                      //   window.location.href = `/Build/Stadium/e/${el.id}`;
                      // }}
                    >
                      <img
                        src={`${el.proofImage}`}
                        // src="http://set-specialeventticketing-com.janus-thesabresolution-com.vps.ezhostingserver.com/assets/proofs/09CFF143-F8D9-471D-B36D7270A122875E.png"
                        alt=""
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ p: { xs: '40px 0px', sm: '40px 0px', md: '30px 64px' } }}
          >
            <Typography
              sx={{
                display: 'table',
                m: '0 auto 20px',
                color: 'textPrimary',
                backgroundColor: 'white',
                // textShadow: '0 0 2px rgba(0,0,0,.7)',
                textAlign: {
                  xs: 'center',
                },
              }}
              variant="h5"
            >
              PHOTO STYLE
            </Typography>
            <Box
              sx={{
                // display: 'flex',
                // alignItems: 'center',
                height: {
                  xs: '275px',
                  sm: '430px',
                  md: '275px',
                  lg: '390px',
                },
              }}
            >
              <Slider
                {...{
                  ...settings,
                  responsive,
                }}
                style={{ width: '100%' }}
              >
                {filteredPhotoTickets.map((el, i) => (
                  <div key={`${el.id}+${el.proofImage}`}>
                    <Link
                      sx={{
                        // 	position: 'relative',
                        width: '100%',
                        display: 'inline-block',
                        height: {
                          xs: '245px',
                          sm: '400px',
                          md: '245px',
                          lg: '360px',
                        },
                      }}
                      component={RouterLink}
                      to={`/Build/Photo/e/${el.id}`}
                      // onClick={() => {
                      //   window.location.href = `/Build/Photo/e/${el.id}`;
                      // }}
                    >
                      <img
                        src={`${el.proofImage}`}
                        // src="http://set-specialeventticketing-com.janus-thesabresolution-com.vps.ezhostingserver.com/assets/proofs/3252670B-A630-143D-C164C7E4074E5805.png"
                        style={{ height: '100%', margin: '0 auto' }}
                        alt=""
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Grid>

        {/* <Typography align="center" color="textPrimary" variant="h3">
				&quot;Devias builds some of the best templates you can find for React.
				They will save you time.&quot;
			</Typography>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					mt: 3,
				}}
			>
				<Avatar src="/static/home/olivier.jpeg" sx={{ mr: 2 }} />
				<Typography color="textSecondary" variant="body2">
					Olivier Tassinari,
					<br />
					co-creator of @MaterialUI
				</Typography>
			</Box> */}
      </Container>
    </Box>
  );
};

PageInspiration.propTypes = {
  applyFilter: PropTypes.bool,
};

export default PageInspiration;
