import numeral from 'numeral';

/* eslint-disable no-nested-ternary */
const perTicketPrice = (qty = 0) => {
  const price = (
    qty <= 50
      ? numeral(1.99)
      : qty <= 200
      ? numeral(1.89)
      : qty <= 499
      ? numeral(1.79)
      : numeral(1.69)
  ).value();
  return price;
};

export default perTicketPrice;
