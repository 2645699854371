/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Toolbar,
  experimentalStyled,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import useAuth from '../hooks/useAuth';
import Logo from './Logo';
import useCart from '../hooks/useCart';
import useSettings from '../hooks/useSettings';
import { homePageLinks } from './MainNavlinks';
import { useState } from 'react';
import './mainStyle.css';

const NavButton = experimentalStyled(Button)(() => ({
  border: 0,
  borderRadius: 0,
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  boxShadow: 'none',
}));

const NavButtonWithDD = experimentalStyled(Button)(() => ({
  border: 0,
  borderRadius: 0,
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  boxShadow: 'none',
}));

const SubMenu = ({ items, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...props}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item, i) => (
          <MenuItem key={i} onClick={handleClose}>
            Profile
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
const MainNavbar = (props) => {
  const {
    settings: {
      reseller: { logo, invertedLogo },
    },
  } = useSettings();
  const { onSidebarMobileOpen } = props;
  const { isAuthenticated, logout } = useAuth();
  const { itemCount } = useCart();

  return (
    <AppBar
      elevation={0}
      sx={{
        borderBottom: (theme) => ({
          xs: 'none',
          sm: 'none',
          md: `3px solid ${theme.palette.primary.main}`,
        }),
        backgroundColor: {
          xs: 'primary.main',
          sm: 'primary.main',
          md: 'white',
        },
        // borderColor: (theme) =>
        // ` !important`,
        // backgroundColor: 'primary',
        // backgroundColor: 'background.paper',
        // color: 'text.secondary',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ minHeight: 80 }}>
          <RouterLink to="/">
            {logo && (
              <Logo
                src={logo}
                sx={{
                  maxHeight: '75px',
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'inline',
                  },
                  // width: { xs: '90%', md: 'auto' },
                }}
              />
            )}
            {/* {!logo && (
							<Logo
								sx={{
									display: {
										md: 'inline',
										xs: 'inline',
									},
									height: 40,
									width: 40,
								}}
							/>
						)} */}
          </RouterLink>
          <RouterLink to="/">
            <Logo
              src={invertedLogo}
              // src="/static/home/SET-Mobile-LOGO.png"
              sx={{
                width: '130px',
                pt: '10px',
                pb: '0px',
                display: {
                  xs: 'inline',
                  sm: 'inline',
                  md: 'none',
                },
              }}
            />
          </RouterLink>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            sx={{
              position: 'absolute',
              right: '0',
              display: {
                md: 'none',
              },
            }}
          >
            {/* {logo && <img src={logo} alt="" />} */}
            <MenuIcon
              sx={{
                fontSize: '2.5rem',
                backgroundColor: 'primary.main',
                border: '2px solid',
                borderRadius: '5px',
              }}
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              height: 80,
              display: {
                md: 'flex',
                xs: 'none',
              },
              p: 0,
              m: 0,
            }}
            flexDirection="column"
          >
            <ButtonGroup
              // justifyContent="flex-end"
              // disableElevation
              variant="contained"
              color="primary"
              sx={{ lineHeight: '25px', height: '25px', boxShadow: 'none' }}
            >
              <Button
                href="http://www.facebook.com/pages/Special-Event-Ticketing/113461275387291"
                target="_blank"
                sx={{ px: '0px' }}
                style={{
                  border: 0,
                  borderRadius: 0,
                  marginRight: '10px',
                  width: '30px',
                  minWidth: '30px',
                  color: 'white',
                }}
              >
                <i className="fab fa-facebook-f" />
              </Button>
              {itemCount > 0 && (
                <Button
                  // disableElevation
                  component={RouterLink}
                  to="/Cart"
                  style={{ borderRadius: 0 }}
                >
                  CART ({itemCount})
                </Button>
              )}
              {isAuthenticated && (
                <Button
                  style={{
                    color: 'white',
                    borderRadius: 0,
                    borderRight: '1px solid white',
                  }}
                  component={RouterLink}
                  to="/Account"
                >
                  ACCOUNT
                </Button>
              )}
              {isAuthenticated && (
                <Button
                  onClick={logout}
                  style={{
                    color: 'white',
                    borderRadius: 0,
                    borderRight: '1px solid white',
                  }}
                >
                  LOGOUT
                </Button>
              )}
              {!isAuthenticated && (
                <NavButton
                  sx={{ color: 'white', borderRight: '1px solid white' }}
                  component={RouterLink}
                  to="/Account"
                  size="small"
                  variant="contained"
                >
                  CUSTOMER LOGIN
                </NavButton>
              )}
              <NavButton
                to="/Resellers"
                component={RouterLink}
                style={{ color: 'white', borderRadius: 0 }}
              >
                RESELLERS
              </NavButton>
            </ButtonGroup>
            <Box
              // disableElevation
              variant="contained"
              color="primary"
              style={{ flexGrow: 1, position: 'relative' }}
            >
              <ButtonGroup
                sx={{
                  lineHeight: 34,
                  height: 34,
                  position: 'absolute',
                  right: 0,
                  bottom: -16,
                }}
              >
                {homePageLinks.map((el, i) => {
                  if (!el.children) {
                    return (
                      <NavButton
                        // color="primary"
                        component={RouterLink}
                        to={el.to}
                        size="small"
                        variant="contained"
                        sx={{
                          '& span': {
                            fontWeight: 'bold',
                            color: 'white',
                          },
                        }}
                        key={i}
                      >
                        {el.label}
                      </NavButton>
                    );
                  }
                  return <DD item={el} key={i} />;
                })}
              </ButtonGroup>
            </Box>
          </Box>
        </Toolbar>
      </Container>
      <Divider />
    </AppBar>
  );
};

const DD = ({ item, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <NavButton
				color="primary"
				// component={RouterLink}
				// to="asd"
				size="small"
				variant="contained"
				// onClick={handleClick}
				aria-expanded={open ? 'true' : undefined}
				onMouseOver={handleClick}
				// onMouseOut={handleClose}
				// {...props}
			>
				{item.label}
			</NavButton> */}
      <Box
        // sx={{ backgroundColor: 'primary.main' }}
        color="primary"
        className="dropdown"
      >
        <NavButton
          className="dropbtn"
          sx={{ height: 34, backgroundColor: 'primary.main' }}
        >
          {item.label}
        </NavButton>
        <div className="dropdown-content">
          {item.children.map((child, i) => (
            <NavButton
              color="primary"
              component={RouterLink}
              to={child.to}
              key={i}
              sx={{
                '& span.MuiButton-label': {
                  color: 'primary',
                },
              }}
            >
              {child.label}
            </NavButton>
          ))}
        </div>
      </Box>
      {/* <Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				sx={{
					borderRadius: '0',
					'& ul': { padding: 0 },
					'& .MuiPaper-rounded': {
						width: 139,
						borderRadius: '0 !important',
					},
				}}
			>
				<div onMouseLeave={handleClose}>
					{item.children.map((child, i) => (
						<NavButton
							color="primary"
							component={RouterLink}
							to={child.to}
							size="small"
							variant="contained"
							key={i}
							sx={{ width: '100%' }}
						>
							{child.label}
						</NavButton>
					))}
				</div>
			</Menu> */}
    </>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

DD.propTypes = {
  item: PropTypes.any,
};

SubMenu.propTypes = {
  items: PropTypes.any,
};

export default MainNavbar;
