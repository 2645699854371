import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import './styles.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  // unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { CartProvider } from './contexts/CartContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import { FeaturedTicketsProvider } from './contexts/FeaturedTicketsContext';
// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory({ window });

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              {/* <HistoryRouter history={history}> */}
              <BrowserRouter>
                <AuthProvider>
                  <CartProvider>
                    <FeaturedTicketsProvider>
                      <App />
                    </FeaturedTicketsProvider>
                  </CartProvider>
                </AuthProvider>
              </BrowserRouter>
              {/* </HistoryRouter> */}
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
