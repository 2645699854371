import { Box, Container } from '@material-ui/core';

const HomeSlider = (props) => (
	<Box
		sx={{
			backgroundColor: 'background.paper',
			mb: '-6px',
		}}
		{...props}
	>
		<Container
			// maxWidth="xl"
			maxWidth="100%"
			sx={{ p: { xs: 0 } }}
		>
			<Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}>
				<img
					alt="Pumpkin"
					src="/static/home/SET-slider-images.jpg"
					style={{ width: '100%' }}
				/>
			</Box>
			<Box sx={{ display: { xs: 'inline', sm: 'inline', md: 'none' } }}>
				<img
					alt="Pumpkin"
					src="/static/home/SET-slider-mobile.jpg"
					style={{ width: '100%' }}
				/>
			</Box>
			{/* <Grid container spacing={3}>
				<Grid item md={4} xs={4}>
					<img alt="Pumpkin" src="/static/home/TicketCarousel-Pumpkin.jpg " />
				</Grid>
				<Grid item md={4} xs={4}>
					<img alt="Pumpkin" src="/static/home/TicketCarousel-Oscars.jpg" />
				</Grid>
				<Grid item md={4} xs={4}>
					<img alt="Pumpkin" src="/static/home/TicketCarousel-Football.jpg" />
				</Grid>
			</Grid> */}
		</Container>
	</Box>
);

export default HomeSlider;
